.filters-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .filter-item {
    display: flex;
    flex-direction: column;
    min-width: 200px;
  }
  
  .filter-item label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .filter-select,
  .filter-input,
  .filter-date-picker {
    padding: 0.5rem;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
  
  .filter-select:focus,
  .filter-input:focus,
  .filter-date-picker:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .legend-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  